@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
}

@layer base {
  body {
    @apply font-poppins;
  }

  div,
  p,
  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-poppins;
  }
}

.custom-carousel .control-dots {
  position: absolute;
  bottom: 80px;
  left: 23px;
  z-index: 10;
  width: auto;
  display: flex;
}

.custom-carousel .control-dots .dot {
  background: transparent;
  border: 1px solid #ffffff !important;
  width: 8px;
  height: 8px;
  margin: 0 4px;
  opacity: 1;
  box-shadow: none;
}

.custom-carousel .control-dots .dot.selected {
  border: 1px solid #c1ab71 !important;
}

.carousel .slider-wrapper li {
  overflow: hidden;
}

@media (min-width: 1300px) {
  @keyframes left-to-right {
    0% {
      transform: translateX(0%);
    }

    100% {
      transform: translateX(-10%);
    }
  }

  .animate-left-to-right {
    animation: left-to-right 5s linear;
  }
}

@media (min-width: 1024px) {
  @keyframes left-to-right {
    0% {
      transform: translateX(0%);
    }

    100% {
      transform: translateX(-40%);
    }
  }

  .animate-left-to-right {
    animation: left-to-right 5s linear;
  }
}

.custom-carousel-new-page .control-dots {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  width: auto;
  display: flex;
}

.custom-carousel-new-page .control-dots .dot {
  background: transparent;
  border: 1px solid #ffffff !important;
  width: 8px;
  height: 8px;
  margin: 0 4px;
  opacity: 1;
  box-shadow: none;
}

.custom-carousel-new-page .control-dots .dot.selected {
  border: 1px solid #c1ab71 !important;
}

.slider-wrapper {
  border-radius: 30px;
}